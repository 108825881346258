import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';
import { useNotification, useLoading } from '@/modules/core';
import { computed } from 'vue';

export function useReconciliationTemplates(variables) {
  const { error } = useNotification();

  const {
    result,
    loading: queryLoading,
    onError,
    refetch,
  } = useQuery(RECONCILIATION_TEMPLATES_QUERY, variables, () => ({
    enabled: !!variables.value.businessId,
  }));

  const reconciliationTemplates = computed(() => result.value?.reconciliationTemplates ?? {});
  const loading = useLoading(queryLoading, variables);

  onError((err) => {
    console.error('useReconciliationTemplates', err);
    error();
  });

  return {
    reconciliationTemplates,
    loading,
    refetch,
  };
}

const RECONCILIATION_TEMPLATES_QUERY = gql`
  query reconciliationTemplates($businessId: ID!) {
    reconciliationTemplates(businessId: $businessId) {
      data {
        supplierId
        autoCreateOrderFromInvoice
        autoCreateOrderFromCreditNote
        consolidatedInvoice
        note
        createBillingFrom
        permanentActivity
        balanceAlignmentSettings {
          triggerBy
          recurrenceFrequency
        }
      }
      default {
        autoCreateOrderFromInvoice
        autoCreateOrderFromCreditNote
        consolidatedInvoice
        createBillingFrom
      }
    }
  }
`;
