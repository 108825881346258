import { computed, ref, watch } from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';

import { useNotification, useLoading } from '@/modules/core';
import { clone } from 'ramda';

export function useReconciliations(variables, options) {
  const { error } = useNotification();

  const { result, loading: queryLoading, onError, refetch } = useQuery(RECONCILIATIONS_QUERY, variables, options);

  const reconciliations = ref([]);
  const reconciliationItems = computed(() => result?.value?.reconciliations.nodes ?? []);

  watch(
    () => reconciliationItems,
    (reconciliationItems) => {
      reconciliations.value = clone(reconciliationItems.value);
    },
    { deep: true }
  );

  const totalCount = computed(() => result.value?.reconciliations?.totalCount ?? 0);
  const loading = useLoading(queryLoading, variables);

  onError((err) => {
    console.error('useReconciliations', err);
    error();
  });

  return {
    reconciliations,
    totalCount,
    loading,
    refetch,
  };
}

export const RECONCILIATIONS_QUERY = gql`
  query reconciliations(
    $businessId: ID
    $supplierId: ID
    $fromPeriodDate: ISODate
    $toPeriodDate: ISODate
    $closed: Boolean
    $pagination: Pagination
    $sort: Sort
    $excludeInactiveCustomers: Boolean
  ) {
    reconciliations(
      businessId: $businessId
      supplierId: $supplierId
      fromPeriodDate: $fromPeriodDate
      toPeriodDate: $toPeriodDate
      closed: $closed
      pagination: $pagination
      sort: $sort
      excludeInactiveCustomers: $excludeInactiveCustomers
    ) {
      nodes {
        id
        paymentDueDate
        paymentTermId
        businessId
        supplierId
        periodStart
        periodEnd
        billingIds
        orderIds
        deliveryIds
        paymentIds
        closed
        customer {
          id
          name
        }
        status {
          status
          details {
            key
            status
            details {
              key
              status
            }
          }
        }
        statusOverride {
          status
          comment
          modifiedAt
          modifiedByDetails {
            firstName
            lastName
          }
        }
        balanceAlignment {
          required
          validated
          note
        }
        unbilledOrders {
          id
          orderId
          businessId
          supplierId
          customerId
          date
          netAmount
          type
        }
        billedAmounts {
          billingId
          amount
          date
        }
        paidAmounts {
          paymentId
          billingId
          amount
        }
        approvals {
          approvedBy
          approvedAt
        }
      }
      totalCount
    }
  }
`;
